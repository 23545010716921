<template>
    <v-row justify="center">
        <div v-if="dialog && default_list">
            <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar>
                        <v-btn icon @click="$emit('onCloseDialog')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ !default_list._id ? "Create" : "Update"}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn :disabled="loading"
                                   text
                                   @click="validate">
                                {{ !default_list._id ? $t('button.create') : $t('button.update') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <Loading :visible="loading" />
                    <v-card-text>
                        <v-container>
                            <v-form ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="default_list.group_no"
                                                      :rules="rules.group_no"
                                                      type="number"
                                                      label="Group No"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="default_list.fight_no"
                                                      :rules="rules.fight_no"
                                                      type="number"
                                                      label="Fight No"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="default_list.channel_type"
                                            :items="channelTypes"
                                            :rules="rules.channel_type"
                                            item-text="name"
                                            item-value="channel_type"
                                            label="Select Channel"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="default_list.co_type_of_betting_id"
                                            :items="resultTypes"
                                            :rules="rules.co_type_of_betting_id"
                                            item-text="name"
                                            item-value="_id"
                                            label="Select Result"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-row>
</template>

<script>
    export default {
        props: {
            selection: {
                type: Array,
                event: "changeSelect",
                default: () => {
                    return [];
                },
            },
            dialog: {
                type: Boolean,
                default: false
            },
            darkmode: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            rules: {
                type: Object,
                default: function () {
                    return {
                        group_no: [
                            v => !!v || 'Group is required',
                        ],
                        fight_no: [
                            v => !!v || 'Fight No is required',
                        ],
                        channel_type: [
                            v => !!v || 'Channel Type is required',
                        ],
                        co_type_of_betting_id: [
                            v => !!v || 'Result is required',
                        ]
                    }
                }
            },
            isValidate: {
                type: Boolean,
                default: true
            },
            
        },
        data: () => ({
            default_list: {
                channel_type: 0,
                co_type_of_betting_id: null,
                group_no: 0,
                fight_no: 0
            }
            
        }),
        computed: {
            valid: {
                // getter
                get: function () {
                    return this.isValidate
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:isValidate', newValue)
                }
            },
            channelTypes() {
                return [
                    { channel_type: 1, name: "COCKCH1" },
                    { channel_type: 2, name: "COCKCH2" },
                    { channel_type: 3, name: "COCKCH3" },
                    { channel_type: 4, name: "COCKCH4" },
                    { channel_type: 15, name: "COCKCH5" },
                    { channel_type: 16, name: "COCKCH6" },
                ];
            },
            resultTypes() {
                return [
                    { _id: "615d4a899e9ab44fe23eb9db", name: "MERON" },
                    { _id: "615d4aa59e9ab44fe23eb9dd", name: "WALA" },
                    { _id: "615d5a609e9ab44fe23eba42", name: "TIE" },
                    { _id: "615d5a759e9ab44fe23eba43", name: "CANCEL" }
                ];
            },
        },
        methods: {
            // select(selected) {
            //     console.log("selected",selected);
            // },
            validate() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit',this.default_list)
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
